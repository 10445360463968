import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  prenom: string;
  email: string;
  password: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as any;
  isAuthenticated = !!JwtService.getToken();
  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = {};
    JwtService.saveToken(data.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  async [Actions.LOGIN](credentials) {
    try {
      const response = await ApiService.post("/auth/login", credentials); // Adjust the API endpoint as per your server setup
      const { data } = response;

      if (data && data.status == "success" && data.user && data.token) {
        // Authentication successful
        this.context.commit(Mutations.SET_AUTH, data);
        return true; // Indicate success
      } else {
        // Authentication failed
        this.context.commit(
          Mutations.SET_ERROR,
          data.errors || { message: "Login failed" }
        );
        return false; // Indicate failure
      }
    } catch (error) {
      // API request failed
      this.context.commit(Mutations.SET_ERROR, {
        message: "Login failed. Please try again later.",
      });
      return false; // Indicate failure
    }
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  async [Actions.VERIFY_AUTH](payload: any) {
    if (this.isAuthenticated == false && JwtService.getToken()) {
      this.context.commit(Mutations.PURGE_AUTH);
    }
    if (JwtService.getToken()) {
      const token = localStorage.getItem("id_token"); // Retrieve the token from localStorage or wherever it's stored

      // Make a POST request to the API endpoint
      fetch(`${process.env.VUE_APP_API_URL}/verify_token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header as well
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          // Handle the response data accordingly, such as setting the user authentication state
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors, such as setting error state or purging authentication state
          this.context.commit(Mutations.SET_ERROR, error.message);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
